import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Services from './services'
import Footer from './footer'
import '../styles/layout.scss'
import '../styles/global.scss'

const Layout = ({ children, data }) => (
  <StaticQuery
    query={graphql`
      query MainMenuQuery {
        wordpressWpApiMenusMenusItems(slug: {eq: "main-nav"}) {
          items {
            title
            object_slug
            url
            type_label
            wordpress_children {
              title
              object_slug
            }
          }
        }
        wordpressPage(slug: {eq: "home"}) {
          acf {
            title
            subtitle
            intro_title
            intro
            hero_image {
              source_url
            }
          }
        }
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            contact_details {
              telephone_number
              email_address
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Header
          menu={data.wordpressWpApiMenusMenusItems.items}
          contact={data.wordpressAcfOptions.options.contact_details}
        />
        <div className="wrap page-wrap">

          {children}
          <Services />
        </div>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

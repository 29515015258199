import React, { Component } from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import StateGallery from '../components/stategallery'
import Contact from '../components/contact'
import SEO from '../components/seo'

export default class Page extends Component {
  render() {
    const { data } = this.props
    if (data.wordpressPage.wordpress_parent !== 0) {
      if (data.wordpressPage.acf && data.wordpressPage.acf.gallery) {
        return (
          <Layout>
            <SEO
              title={
                data.wordpressPage.acf.seo_page_title !== null
                  ? data.wordpressPage.acf.seo_page_title
                  : data.wordpressPage.title
              }
              description={
                data.wordpressPage.acf.seo_page_meta_description !== null &&
                data.wordpressPage.acf.seo_page_meta_description
              }
              keywords={
                data.wordpressPage.acf.seo_page_keywords !== null &&
                data.wordpressPage.acf.seo_page_keywords
              }
            />
            <div>
              <h1 id="top">{data.wordpressPage.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.content,
                }}
              />
              <ul className="page_subnav">
                {data.allWordpressPage.edges.map(item => (
                  <li key={item.node.title}>
                    <Link
                      to={`${item.node.link}`}
                      title={`${item.node.title}`}
                    >
                      <span>{item.node.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
              <StateGallery sliderImages={data.wordpressPage.acf.gallery} />
            </div>
          </Layout>
        )
      } else {
        return (
          <Layout>
            <SEO
              title={
                data.wordpressPage.acf.seo_page_title !== null
                  ? data.wordpressPage.acf.seo_page_title
                  : data.wordpressPage.title
              }
              description={
                data.wordpressPage.acf.seo_page_meta_description !== null &&
                data.wordpressPage.acf.seo_page_meta_description
              }
              keywords={
                data.wordpressPage.acf.seo_page_keywords !== null &&
                data.wordpressPage.acf.seo_page_keywords
              }
            />
            <div>
              <h1>{data.wordpressPage.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.content,
                }}
              />
              <ul className="page_subnav">
                {data.allWordpressPage.edges.map(item => (
                  <li key={item.node.title}>
                    <Link
                      to={`${item.node.link}`}
                      title={`${item.node.title}`}
                    >
                      <span>{item.node.title}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </Layout>
        )
      }
    } else {
      if (data.wordpressPage.acf && data.wordpressPage.acf.gallery) {
        return (
          <Layout>
            <SEO
              title={
                data.wordpressPage.acf.seo_page_title !== null
                  ? data.wordpressPage.acf.seo_page_title
                  : data.wordpressPage.title
              }
              description={
                data.wordpressPage.acf.seo_page_meta_description !== null &&
                data.wordpressPage.acf.seo_page_meta_description
              }
              keywords={
                data.wordpressPage.acf.seo_page_keywords !== null &&
                data.wordpressPage.acf.seo_page_keywords
              }
            />
            <div>
              <h1 id="top">{data.wordpressPage.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wordpressPage.content,
                }}
              />
              <StateGallery sliderImages={data.wordpressPage.acf.gallery} />
            </div>
          </Layout>
        )
      } else {
        if (data.wordpressPage.slug === 'contact') {
          return (
            <Layout>
              <SEO
                title={
                  data.wordpressPage.acf.seo_page_title !== null
                    ? data.wordpressPage.acf.seo_page_title
                    : data.wordpressPage.title
                }
                description={
                  data.wordpressPage.acf.seo_page_meta_description !== null &&
                  data.wordpressPage.acf.seo_page_meta_description
                }
                keywords={
                  data.wordpressPage.acf.seo_page_keywords !== null &&
                  data.wordpressPage.acf.seo_page_keywords
                }
              />
              <div>
                <h1>{data.wordpressPage.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.content,
                  }}
                />
                <Contact />
              </div>
            </Layout>
          )
        } else {
          return (
            <Layout>
              <SEO
                title={
                  data.wordpressPage.acf.seo_page_title !== null
                    ? data.wordpressPage.acf.seo_page_title
                    : data.wordpressPage.title
                }
                description={
                  data.wordpressPage.acf.seo_page_meta_description !== null &&
                  data.wordpressPage.acf.seo_page_meta_description
                }
                keywords={
                  data.wordpressPage.acf.seo_page_keywords !== null &&
                  data.wordpressPage.acf.seo_page_keywords
                }
              />
              <div>
                <h1>{data.wordpressPage.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.content,
                  }}
                />
              </div>
            </Layout>
          )
        }
      }
    }
  }
}

export const query = graphql`
  query PageQuery($slug: String!, $wpParent: Int) {
    wordpressPage(slug: { eq: $slug }) {
      title
      slug
      content
      wordpress_id
      wordpress_parent
      acf {
        seo_page_title
        seo_page_keywords
        seo_page_meta_description
        gallery {
          title
          alt_text
          caption
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
          }
        }
      }
    }
    wordpressWpApiMenusMenusItems(slug: { eq: "main-nav" }) {
      items {
        title
        object_slug
        order
        wordpress_children {
          title
          object_slug
          order
        }
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: $wpParent } }) {
      edges {
        node {
          title
          link
          wordpress_parent
          wordpress_id
        }
      }
    }
  }
`

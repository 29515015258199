import React, { Component } from 'react'
import Img from 'gatsby-image'
import '../styles/state.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default class StateGallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      counter: 0,
    }
  }

  render() {
    const data = this.props.sliderImages
    return (
      <div>
        <div className="large_image">
          <img
            src={
              data[this.state.counter].localFile.childImageSharp.original.src
            }
            alt={data[this.state.counter].title}
          />
        </div>
        {data[this.state.counter].caption !== '' && (
          <div
            className="gallery_caption"
            dangerouslySetInnerHTML={{
              __html: data[this.state.counter].caption,
            }}
          />
        )}
        <div className="thumbs">
          {data.map((item, index) => (
            <AnchorLink
              href="#top"
              onClick={() => this.setState({ counter: index })}
              key={index}
            >
              <Img
                className="thumb"
                fluid={item.localFile.childImageSharp.fluid}
                alt={data[`${index}`].title}
              />
            </AnchorLink>
          ))}
        </div>
      </div>
    )
  }
}
